import React from 'react';
import PrivacyPolicy from '../../components/Landing-info/PrivacyPolicy/PrivacyPolicy';

import Layout from '../../components/Layout/Layout';

import styles from './index.module.scss';

const Index = () => {
    return (
        <Layout mainClasses="section-padded-wrapper flex-grow-1">
            <div className={styles.container}>
                <PrivacyPolicy />
            </div>
        </Layout>
    );
};

export default Index;